export default {
  bind: function(el, binding, vnode) {
    el.addEventListener('keypress', (e) => {
      let charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault();
      } else {
        return true;
      }
    });
  }
};
