import $ from 'jquery';

const SpinnerService = {
  async showLoader(){
    const html = `<ul class="custom-loader">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>`;
    const ele = document.getElementsByClassName(`custom-loader-overlay`);
    if(ele.length==0){
      const node = document.createElement("div");
      node.className = "custom-loader-overlay";
      node.innerHTML = html;
      document.body.appendChild(node);
    }else{
      $('.custom-loader-overlay').fadeIn(400);
    }
  },
  async hideLoader(){
    $('.custom-loader-overlay').fadeOut(1000);
  }
};

export default SpinnerService;
