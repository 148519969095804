import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Select2 from 'v-select2-component';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import './assets/scss/loader.scss'; // for bubble theme

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import VueExcelXlsx from "vue-excel-xlsx";

// router setup
import router from './routes/router';
import Paginate from 'vuejs-paginate';

import 'sweetalert2/src/sweetalert2.scss';

import AlertUtils from './util/alertUtils';
import HttpServices from './services/httpServices';
import numberOnly from './directives/number-only';
import decimalFormat from './directives/decimal-format';
import datepicker from './directives/datepicker';
import SessionStorageUtils from './util/sessionStorageUtils';
import SpinnerService from "./services/spinnerService";
import PermissionServices from './services/PermissionServices';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueQuillEditor, /* { default global options } */);
Vue.use(VueExcelXlsx);
Vue.component('paginate', Paginate);
Vue.component('DatePicker', DatePicker);
Vue.component('Select2', Select2);
Vue.component('VueSlickCarousel', VueSlickCarousel);

Vue.prototype.AlertUtils = AlertUtils;
Vue.prototype.HttpServices = HttpServices;
Vue.prototype.SessionStorageUtils = SessionStorageUtils;
Vue.prototype.SpinnerService = SpinnerService;
Vue.prototype.PermissionServices = PermissionServices;

Vue.directive('numberOnly', numberOnly);
Vue.directive('decimalFormat', decimalFormat);
Vue.directive('datepicker', datepicker);

Vue.filter("numberIntFormat", function (value) {
  if(value==undefined||value==""||value==null){
    return "0";
  }
  return Number(""+value).toLocaleString("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
});
Vue.filter("numberDecimalFormat", function (value) {
  if(value==undefined||value==""||value==null){
    return "0";
  }
  return Number(""+value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
