<template>
  <div class="wrapper" >
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: 'Dashboard 2', path: '/dashboard2', icon: 'fas fa-file-alt text-white'}"></sidebar-item>
        <sidebar-item :link="{ name: 'Supplier Dashboard', path: '/supplier-dashboard', icon: 'fas fa-file-alt text-white'}"></sidebar-item>
        <template v-for="(v, index) of menus">
          <sidebar-item :key="`menu_${v.id}-${index}`" :link="{ name: v.app_name, icon: v.icon}" :menu="true" v-if="v.children">
              <li v-for="(vv, i) of v.children" :key="`submenu_${vv.id}-${i}`"><router-link :to="`/${vv.app_slug}`" class="nav-link"><i class="fa fa-dot-circle text-xs mr--4"></i> {{vv.app_name}}</router-link></li>
          </sidebar-item>
          <sidebar-item :key="`menu_${v.id}-${index}`" :link="{name: v.app_name, path: `/${v.app_slug}`,icon: v.icon,}" v-else></sidebar-item>
        </template>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';
  import SessionStorageUtils from '../../util/sessionStorageUtils';
  import moment from 'moment';

  export default {
    data() {
      return {
        menus: []
      };
    },
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    async mounted() {
      this.initScrollbar();
      const user = await SessionStorageUtils.decodeUser();
      //this.menus = await this.HttpServices.getMasterData(`/master/getMenus/${user.group_id}`);

    //window.sessionStorage.removeItem("menu_master_data");
      const current_unix_time = moment().unix();
      let menu_master = window.sessionStorage.getItem("menu_master_data");

      if(menu_master == null){
        this.menus = await this.HttpServices.getMasterData(`/master/getMenus/${user.group_id}`);
        window.sessionStorage.setItem("menu_master_data",JSON.stringify({
          updated : current_unix_time,
          data : this.menus
        }));
      }else{
        menu_master = JSON.parse(menu_master);
        if(menu_master.updated + 3600 < current_unix_time){
          this.menus = await this.HttpServices.getMasterData(`/master/getMenus/${user.group_id}`);
          window.sessionStorage.setItem("menu_master_data",JSON.stringify({
            updated : current_unix_time,
            data : this.menus
          }));
        }else{
          this.menus = menu_master.data;
          console.log("Get Menu From Storage");
        }
      }

    }
  };
</script>
<style lang="scss">

</style>
