import $ from 'jquery';

export default {
  bind: function(el, binding, vnode) {
    el.addEventListener('keypress', (e) => {
      let keyChar = String.fromCharCode(e.which);
      let val = $(el).val();
      let indexOfDot = val.match(/[\.]/g);
      if(indexOfDot){
        indexOfDot = indexOfDot.length;
      }else{
        indexOfDot = -1;
      }
      if(keyChar=="."&&indexOfDot>0){
        e.preventDefault();
        return false;
      }
      indexOfDot = val.match(/[\-]/g);
      if(indexOfDot){
        indexOfDot = indexOfDot.length;
      }else{
        indexOfDot = -1;
      }
      if(keyChar=="-"&&indexOfDot>0){
        e.preventDefault();
        return false;
      }
      if(!keyChar.match(/[0-9\.\-]/g)){
        e.preventDefault();
        return false;
      }

      return true;
    });
    el.addEventListener('keyup', (e) => {
      let keyChar = String.fromCharCode(e.which);
      let val = $(el).val();
      let indexOfDot = val.indexOf("\.");
      if((e.which==190||e.which==110)&&indexOfDot==0){
        val = val.replaceAll("\.", "");
        $(el).val(val);
        e.preventDefault();
        return false;
      }
      indexOfDot = val.indexOf("-");
      if((e.which==189||e.which==109)&&indexOfDot>0){
        val = val.replaceAll("-", "");
        $(el).val(val);
        e.preventDefault();
        return false;
      }
      return true;
    });
  }
};
