import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';
import authMiddleware from '../middleware/authMiddleware';

const routes = [{
  path: '/',
  redirect: 'dashboard',
  component: DashboardLayout,
  meta: {
    middleware: authMiddleware
  },
  children: [

  {path: '/user',name: 'user',component: () => import('../apps/user/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/user/add',name: 'user add',component: () => import('../apps/user/view/page.user.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/user/edit/:id',name: 'user edit',component: () => import('../apps/user/view/page.user.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/group',name: 'group',component: () => import('../apps/group/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/group/add',name: 'group add', component: () => import('../apps/group/view/page.group.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/group/edit/:id',name: 'group edit', component: () => import('../apps/group/view/page.group.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/group/permission/:id',name: 'group permission', component: () => import('../apps/group/view/page.group.permission.vue'), meta: {middleware: authMiddleware}},
  {path: '/place',name: 'place',component: () => import('../apps/place/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/product',name: 'product',component: () => import('../apps/product/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/product/add',name: 'product add',component: () => import('../apps/product/view/page.product.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/product/edit/:id',name: 'product edit',component: () => import('../apps/product/view/page.product.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-tag',name: 'product tag',component: () => import('../apps/product_tag/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-tag/add',name: 'product tag add',component: () => import('../apps/product_tag/view/page.product_tag.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-tag/edit/:id',name: 'product tag edit',component: () => import('../apps/product_tag/view/page.product_tag.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-category',name: 'product category',component: () => import('../apps/product_category/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-category/add',name: 'product category add',component: () => import('../apps/product_category/view/page.product_category.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/product-category/edit/:id',name: 'product category edit',component: () => import('../apps/product_category/view/page.product_category.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier',name: 'supplier',component: () => import('../apps/supplier/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier/add',name: 'supplier add',component: () => import('../apps/supplier/view/page.supplier.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier/edit/:id',name: 'supplier edit',component: () => import('../apps/supplier/view/page.supplier.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-contract',name: 'supplier contract',component: () => import('../apps/supplier_contact/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-contract/add',name: 'supplier contract add',component: () => import('../apps/supplier_contact/view/page.supplier_contact.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-contract/edit/:id',name: 'supplier contract edit',component: () => import('../apps/supplier_contact/view/page.supplier_contact.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-product',name: 'supplier-product mapping',component: () => import('../apps/supplier_mapping_product/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-product/edit/:id',name: 'supplier-product mapping edit',component: () => import('../apps/supplier_mapping_product/view/page.supplier_mapping_product.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-product/preview/:id',name: 'supplier-product mapping preview',component: () => import('../apps/supplier_mapping_product/view/page.product.preview.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-location',name: 'supplier-location mapping',component: () => import('../apps/supplier_mapping_location/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-location/edit/:id',name: 'supplier-location mapping edit',component: () => import('../apps/supplier_mapping_location/view/page.supplier_mapping_location.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-mapping-location/preview/:id', name: 'supplier-location mapping preview',component: () => import('../apps/supplier_mapping_location/view/page.location.preview.vue'), meta: {middleware: authMiddleware}},
  {path: '/store',name: 'store',component: () => import('../apps/store/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/store/add',name: 'store add',component: () => import('../apps/store/view/page.store.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/store/edit/:id',name: 'store edit',component: () => import('../apps/store/view/page.store.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/store-mapping-supplier',name: 'store-supplier mapping',component: () => import('../apps/store_mapping_supplier/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/store/permission/:id',name: 'store edit permission',component: () => import('../apps/store/view/page.customer.role.vue'), meta: {middleware: authMiddleware}},
  {path: '/store-mapping-product',name: 'store-product mapping',component: () => import('../apps/store_mapping_product/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/store-mapping-product/edit/:id',name: 'store-product mapping edit',component: () => import('../apps/store_mapping_product/view/page.store_mapping_product.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/store-mapping-product/preview/:id',name: 'store-product mapping preview',component: () => import('../apps/store_mapping_product/view/page.product.preview.vue'), meta: {middleware: authMiddleware}},
  {path: '/promotion',name: 'promotion',component: () => import('../apps/promotion/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/promotion/add',name: 'promotion add',component: () => import('../apps/promotion/view/page.promotion.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/promotion/edit/:id',name: 'promotion edit',component: () => import('../apps/promotion//view/page.promotion.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/promotion/manage/:id',name: 'promotion manange',component: () => import('../apps/promotion/view/page.manage.product.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer',name: 'customer',component: () => import('../apps/customer/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/order',name: 'order',component: () => import('../apps/order/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/add',name: 'order add',component: () => import('../apps/order/view/page.order.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/add_new',name: 'order add new',component: () => import('../apps/order/view/page.order.add_new.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/edit/:id',name: 'order edit',component: () => import('../apps/order/view/page.order.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/edit_new/:id',name: 'order edit',component: () => import('../apps/order/view/page.order.edit_new.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/view/:id',name: 'order view',component: () => import('../apps/order/view/page.order.view.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/detail/:id',name: 'order detail',component: () => import('../apps/order/view/page.order.detail.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/bill_order/:id',name: 'order bill',component: () => import('../apps/order/view/page.bill.order.vue'), meta: {middleware: authMiddleware}},
  {path: '/order/bulk_bill/:id',name: 'order bulk bill',component: () => import('../apps/order/view/page.bulk_bill.order.vue'), meta: {middleware: authMiddleware}},
  {path: '/order_new',name: 'order new',component: () => import('../apps/order/index_new.vue'), meta: {middleware: authMiddleware}},
  {path: '/order_new/bulk_bill_new/:id',name: 'order bulk bill new',component: () => import('../apps/order/view/page.bulk_bill_new.order.vue'), meta: {middleware: authMiddleware}},
  {path: '/database',name: 'database',component: () => import('../apps/database/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/setting',name: 'setting',component: () => import('../apps/setting/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/profile', name: 'profile', component: () => import('../apps/profile/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/change-password', name: 'change password', component: () => import('../apps/change_password/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer/add', name: 'customer add', component: () => import('../apps/customer/view/page.customer.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer/edit/:id', name: 'customer edit', component: () => import('../apps/customer/view/page.customer.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer/history_order/:id', name: 'customer history order', component: () => import('../apps/customer/view/page.history.order.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer-credit', name: 'customer credit', component: () => import('../apps/customer_credit/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/customer-merge', name: 'customer merge', component: () => import('../apps/customer_merge/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/location', name: 'location', component: () => import('../apps/location/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/location/add', name: 'location add', component: () => import('../apps/location/view/page.location.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/location/edit/:id', name: 'location edit', component: () => import('../apps/location/view/page.location.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-group', name: 'location group', component: () => import('../apps/location_group/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-group/add', name: 'location group add', component: () => import('../apps/location_group/view/page.location_group.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-group/edit/:id', name: 'location group edit', component: () => import('../apps/location_group/view/page.location_group.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-type', name: 'location type', component: () => import('../apps/location_type/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-type/add', name: 'location type add', component: () => import('../apps/location_type/view/page.location_type.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/location-type/edit/:id', name: 'location type edit', component: () => import('../apps/location_type/view/page.location_type.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/channel', name: 'channel', component: () => import('../apps/channel/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/channel/add', name: 'channel add', component: () => import('../apps/channel/view/page.channel.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/channel/edit/:id', name: 'channel edit', component: () => import('../apps/channel/view/page.channel.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/payment', name: 'payment', component: () => import('../apps/payment/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/payment/add', name: 'payment add', component: () => import('../apps/payment/view/page.payment.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/payment/edit/:id', name: 'payment edit', component: () => import('../apps/payment/view/page.payment.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/delivery', name: 'delivery', component: () => import('../apps/delivery/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/delivery/edit/:id', name: 'delivery edit', component: () => import('../apps/delivery/view/page.delivery.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/delivery/order_detail/:id', name: 'delivery order detail', component: () => import('../apps/delivery/view/page.order.detail.vue'), meta: {middleware: authMiddleware}},
  {path: '/delivery/payment_view/:id',name: 'delivery payment view',component: () => import('../apps/delivery/view/page.order.view.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue', name: 'issue', component: () => import('../apps/issue/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue/edit/:id', name: 'issue edit', component: () => import('../apps/issue/view/page.issue.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue/resolve/:id', name: 'issue resolve', component: () => import('../apps/issue/view/page.resolve.issue.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue-type', name: 'issue type', component: () => import('../apps/issue_type/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue-type/add', name: 'issue type add', component: () => import('../apps/issue_type/view/page.issue_type.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/issue-type/edit/:id', name: 'issue type edit', component: () => import('../apps/issue_type/view/page.issue_type.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/report', name: 'report', component: () => import('../apps/report/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/report/count_detail', name: 'report count detail', component: () => import('../apps/report//view/page.count.detail.vue'), meta: {middleware: authMiddleware}},
  {path: '/upsale', name: 'upsale', component: () => import('../apps/upsale/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/upsale/product/:id', name: 'upsale product', component: () => import('../apps/upsale/view/page.product.vue'), meta: {middleware: authMiddleware}},
  {path: '/dashboard2', name: 'dashboard 2', component: () => import('../apps/dashboard/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/evaluate', name: 'evaluate', component: () => import('../apps/evaluate/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/evaluate/order_detail/:id', name: 'evaluate order detail', component: () => import('../apps/evaluate/view/page.order.detail.vue'), meta: {middleware: authMiddleware}},
  {path: '/evaluate/manager/:id/:payment_id?/:delivery_id?', name: 'evaluate manager', component: () => import('../apps/evaluate/view/page.manager.vue'), meta: {middleware: authMiddleware}},
  {path: '/statement', name: 'statement', component: () => import('../apps/statement/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/supplier-dashboard', name: 'supplier dashboard', component: () => import('../apps/supplier_dashboard/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/template', name: 'template', component: () => import('../apps/template/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/template/add',name: 'template add',component: () => import('../apps/template/view/page.template.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/template/edit/:id',name: 'template edit',component: () => import('../apps/template/view/page.template.edit.vue'), meta: {middleware: authMiddleware}},
  {path: '/order-cancel-reason', name: 'order_cancel_reason', component: () => import('../apps/order_cancel_reason/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/order-cancel-reason/add', name: 'order_cancel_reason add', component: () => import('../apps/order_cancel_reason/view/page.order_cancel_reason.add.vue'), meta: {middleware: authMiddleware}},
  {path: '/refund', name: 'refund', component: () => import('../apps/refund/index.vue'), meta: {middleware: authMiddleware}},
  {path: '/refund/edit/:id', name: 'refund edit', component: () => import('../apps/refund/view/page.refund.edit.vue'), meta: {middleware: authMiddleware}},
  
  {path: '/tools', name: 'tools', component: () => import('../apps/tools/index.vue'), meta: {middleware: authMiddleware}},

  {
    path: '/dashboard',
    name: 'dashboard',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue'),
    meta: {
      middleware: authMiddleware
    }
      },
      {
        path: '/icons',
        name: 'icons',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Icons.vue')
      },
      // {
      //   path: '/profile',
      //   name: 'profile',
      //   component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue')
      // },
      {
        path: '/maps',
        name: 'maps',
        component: () => import(/* webpackChunkName: "demo" */ '../views/GoogleMaps.vue')
      },
      {
        path: '/tables',
        name: 'tables',
        component: () => import(/* webpackChunkName: "demo" */ '../views/RegularTables.vue')
      }
  ]},{
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Register.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
