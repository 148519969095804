import Swal from 'sweetalert2/dist/sweetalert2';

const AlertUtils = {
    alert(icon, msg) {
        Swal.fire({
            title: "",
            text: msg,
            icon: icon,
            showCancelButton: false,
            "confirmButtonClass": "btn btn-primary",
            "confirmButtonText": 'ตกลง'
        }).then(async(result) => {});
    },
    confirm(icon, msg, cb, path) {
        Swal.fire({
            title: "",
            text: msg,
            icon: icon,
            showCancelButton: true,
            "cancelButtonClass": "btn btn-secondary",
            "cancelButtonText": "ยกเลิก",
            "confirmButtonClass": "btn btn-primary",
            "confirmButtonText": 'ตกลง'
        }).then(async(result) => {
            if (result.value) {
              if(cb){
                cb();
              }
            }
            else if (result.isDismissed) { 
                window.location.href = path; 
            }
        });
    },
    alertCallback(icon, msg, cb) {
        Swal.fire({
            title: "",
            text: msg,
            icon: icon,
            showCancelButton: false,
            "confirmButtonClass": "btn btn-primary",
            "confirmButtonText": 'ตกลง'
        }).then(async(result) => {
          cb();
        });
    }
}

export default AlertUtils;
