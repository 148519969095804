import Constants from "./constants";

const StringUtils = {
  getIconOrderStatus(status) {
    if (status == 0) {
      return "fas fa-clipboard-check text-primary";
    } else if (status == 1) {
      return "far fa-check-square text-light";
    } else if (status == 2) {
      return "far fa-check-circle text-success";
    } else if (status == 3) {
      return "fas fa-share-square text-dark";
    } else if (status == 4) {
      return "fas fa-check-double text-info";
    } else if (status == 5) {
      return "fas fa-exclamation-circle text-danger";
    } else if (status == 6) {
      return "fas fa-hand-holding-usd text-primary";
    } else if (status == 7) {
      return "fas fa-times text-danger";
    }

    return "";
  },
  getIconPaymentStatus(status) {
    if (status == 0) {
      return "fas fa-shopping-basket text-light";
    } else if (status == 1) {
      return "fas fa-shopping-basket text-primary";
    } else if (status == 2) {
      return "fas fa-search-dollar text-info";
    } else if (status == 3) {
      return "fas fa-check-double text-success";
    } else if (status == 4) {
      return "fas fa-times text-danger";
    }

    return "";
  },
  getIconDeliveryStatus(status) {
    if (status == 0) {
      return "fas fa-truck fa-flip-horizontal text-light";
    } else if (status == 1) {
      return "fas fa-portrait text-primary";
    } else if (status == 2) {
      return "far fa-images text-light";
    } else if (status == 3) {
      return "far fa-image text-info";
    } else if (status == 4) {
      return "fas fa-file-image text-success";
    }

    return "";
  },
  getIconInspectionStatus(status) {
    if (status == 1) {
      return "fas fa-check-square text-primary";
    } else if (status == 2) {
      return "fas fa-check-circle text-success";
    }

    return "";
  },
  getIconPrintStatus(status) {
    if (status == 0) {
      return "far fa-check-square text-dark";
      //return "fas fa-print text-light";
    } else if (status == 1) {
      return "far fa-square text-light";
      //return "fas fa-print text-success";
    }

    return "";
  },
  getIconDeliveriesStatus(status) {
    if (status == 0) {
      return "far fa-file-alt text-primary";
    } else if (status == 1) {
      return "fas fa-clipboard-check text-gray-300";
    } else if (status == 2) {
      return "fas fa-images text-purple";
    } else if (status == 3) {
      return "far fa-images text-orange";
    } else if (status == 4) {
      return "fas fa-exclamation-circle text-danger";
    } else if (status == 5) {
      return "fas fa-file-image text-indigo";
    } else if (status == 6) {
      return "fas fa-exclamation text-warning";
    } else if (status == 7) {
      return "fas fa-check-square text-success";
    }

    return "";
  },
  getIconPaymentType(status) {
    return "";
  },
  getIconUpsaleStatus(status) {
    if (status == 0) {
      return "fas fa-check-square text-primary";
    } else if (status == 1) {
      return "fas fa-check-circle text-success";
    } else if (status == 2) {
      return "fas fa-times-circle text-danger";
    }

    return "";
  },

  getTextOrderStatus(status) {
    const datas = Constants.order_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextPaymentStatus(status) {
    const datas = Constants.payment_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextDeliveryStatus(status) {
    const datas = Constants.delivery_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextInspectionStatus(status) {
    const datas = Constants.inspections_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextPrintStatus(status) {
    const datas = Constants.print_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextDeliveriesStatus(status) {
    const datas = Constants.deliveries_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextPaymentType(status) {
    const datas = Constants.payment_types;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextUpsaleStatus(status) {
    const datas = Constants.upsale_status;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextCustomerType(cusType) {
    const datas = Constants.customer_types;
    const d = datas.find(v => v.id == cusType);
    if (d) {
      return d;
    }
    return { id: cusType, text: "" };
  },
  getTextCustomerCreditStatus(status) {
    const datas = Constants.customer_credit_types;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },
  getTextIssuePriority(status) {
    const datas = Constants.issue_priority;
    const d = datas.find(v => v.id == status);
    if (d) {
      return d;
    }
    return { id: status, text: "" };
  },

  random4Digit() {
    return Math.floor(1000 + Math.random() * 9000);
  },

  dataURItoBlob(dataURI) {
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    const mime = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  },

  blobToFile(newBlob, fileName) {
    // newBlob.lastModifiedDate = new Date();
    // newBlob.name = fileName;
    return new File([newBlob], fileName, {
      lastModified: new Date().getTime(),
      type: newBlob.type
    });
  },

  resizeImage({ file, maxSize }) {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement("canvas");

    const resize = () => {
      let { width, height } = image;

      // if (width > height) {
      //   if (width > maxSize) {
      //     height *= maxSize / width;
      //     width = maxSize;
      //   }
      // } else if (height > maxSize) {
      //   width *= maxSize / height;
      //   height = maxSize;
      // }
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);

      const dataUrl = canvas.toDataURL(file.type);

      return this.dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }

      reader.onload = readerEvent => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    });
  },

  async resizeBlobToFile({ file, maxSize }) {
    const newBlob = await this.resizeImage({ file: file, maxSize: maxSize });
    return this.blobToFile(newBlob, file.name);
  }
};

export default StringUtils;
