import SessionStorageUtils from "../util/sessionStorageUtils";

export default async function authMiddleware({ next, router }) {
  const validUser = await SessionStorageUtils.checkUser();
  if (!validUser) {
    return router.push("/login").catch(err => {});
  }

  return next();
}
