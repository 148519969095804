import axios from "axios";
import qs from "qs";
import SessionStorageUtils from "../util/sessionStorageUtils";
import Config from "../util/config";
import AlertUtils from "../util/alertUtils";
import StringUtils from "../util/StringUtils";
// import SpinnerService from "./spinnerService";

const HttpServices = {
  generate_url(url){
    return Config.apiUrl + url;

  },
  async getMasterData(url) {
    return await new Promise(async (resolve, reject) => {
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .get(Config.apiUrl + url, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async getData(url) {
    return await new Promise(async (resolve, reject) => {
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .get(Config.apiUrl + url, headers)
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async postData(url, params) {
    return await new Promise(async (resolve, reject) => {
      params = qs.stringify(params);
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .post(Config.apiUrl + url, params, headers)
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async putData(url, params) {
    return await new Promise(async (resolve, reject) => {
      params = qs.stringify(params);
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .put(Config.apiUrl + url, params, headers)
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async postFormData(url, params) {
    return await new Promise(async (resolve, reject) => {
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .post(Config.apiUrl + url, params, headers)
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async putFormData(url, params) {
    return await new Promise(async (resolve, reject) => {
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .put(Config.apiUrl + url, params, headers)
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async deleteData(url) {
    return await new Promise(async (resolve, reject) => {
      const headers = await this.getHeader();
      // SpinnerService.showLoader();
      await axios
        .delete(Config.apiUrl + url, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async postAuthenData(url, params) {
    return await new Promise(async (resolve, reject) => {
      params = qs.stringify(params);
      // SpinnerService.showLoader();
      await axios
        .post(Config.apiUrl + url, params, {
          headers: { "X-Authorization": Config.apiToken }
        })
        .then(async res => {
          const data = res.data;
          if (
            data &&
            (data.status.code == "401" || data.status.code == "403")
          ) {
            SessionStorageUtils.deleteAllSession();
          }
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async easyUploadS3(token,file,bucket) {
    const mediaUpload = {};
    if (file) {
      let params = new FormData();
      params.append("file", file);
      params.append("title", file.name);
      params.append("file_category", bucket);
      const resultMedia = await this.postFormDataS3(params,token);
      if (resultMedia.media && resultMedia.media.length > 0) {
        const media = resultMedia.media[0];
        mediaUpload.id = media.id;
        mediaUpload.original_name = media.original_name;
        mediaUpload.mime_type = media.mime_type;
        mediaUpload.size = media.size;
        mediaUpload.url = media.url;

        // Thumbnail Photo
        params = new FormData();
        const thumbFile = await StringUtils.resizeBlobToFile({file: file,maxSize: 200});
        params.append("file", thumbFile);
        params.append("title", thumbFile.name);
        params.append("file_category", bucket);
        let resultThumb = await this.postFormDataS3(params,token);
        if (resultThumb.media && resultThumb.media.length > 0) {
          const mediaThumb = resultThumb.media[0];
          mediaUpload.id_t = mediaThumb.id;
          mediaUpload.original_name_t = mediaThumb.original_name;
          mediaUpload.mime_type_t = mediaThumb.mime_type;
          mediaUpload.size_t = mediaThumb.size;
          mediaUpload.url_t = mediaThumb.url;
          
          return mediaUpload;
        }
      }
    }else{
      return false;
    }
  },
  async authenS3() {
    return await new Promise(async (resolve, reject) => {
      const params = new FormData();
      params.append("email", Config.s3.email);
      params.append("password", Config.s3.password);
      const headers = { headers: { Accept: "application/json" } };
      await axios
        .post(Config.s3.url + "/authen", params, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async postFormDataS3(params, accessToken) {
    return await new Promise(async (resolve, reject) => {
      const headers = {
        headers: {
          Accept: "application/json",
          language: "th-TH",
          Authorization: "Bearer " + accessToken
        }
      };
      params.append("created_by", "1");
      params.append("updated_by", "1");
      await axios
        .post(Config.s3.url + "/product-image", params, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async putFormDataS3(params, accessToken) {
    return await new Promise(async (resolve, reject) => {
      const headers = {
        headers: {
          Accept: "application/json",
          language: "th-TH",
          Authorization: "Bearer " + accessToken
        }
      };
      params.append("created_by", "1");
      params.append("updated_by", "1");
      await axios
        .put(Config.s3.url + "/product-image", params, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async deleteDataS3(id, accessToken) {
    return await new Promise(async (resolve, reject) => {
      const headers = {
        headers: {
          Accept: "application/json",
          language: "th-TH",
          Authorization: "Bearer " + accessToken
        }
      };
      await axios
        .delete(Config.s3.url + "/product-image/" + id, headers)
        .then(async res => {
          const data = res.data;
          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },

  async getDataFile(url) {
    return await new Promise(async (resolve, reject) => {
      const user = await SessionStorageUtils.getUser();
      await axios
        .get(Config.apiUrl + url, {
          headers: {
            "X-Authorization": Config.apiToken,
            Authorization: user ? user.accessToken : ""
          },
          responseType: "blob"
        })
        .then(async res => {
          const data = res.data;

          resolve(data);
        })
        .catch(res => {
          AlertUtils.alert("error", `${res}`);
          resolve(false);
        })
        .finally(() => {
          // SpinnerService.hideLoader();
        });
    });
  },
  async getHeader() {
    const user = await SessionStorageUtils.getUser();
    const headers = {
      headers: {
        "X-Authorization": Config.apiToken,
        Authorization: user ? user.accessToken : ""
      }
    };

    return headers;
  },
  createHeader() {
    const user = SessionStorageUtils.getUserNow();
    const headers = {
        "X-Authorization": Config.apiToken,
        "Authorization": user ? user.accessToken : ""
    };

    return headers;
  }
};

export default HttpServices;
