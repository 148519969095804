<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark bg-dark" id="sidenav-main">
        <div class="container-fluid">
            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img"  alt="...">
            </router-link>
            <slot name="mobile-right">
              <ul class="nav align-items-center d-md-none">
                <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                    <a slot="title-container" class="nav-link nav-link-icon" href="#" role="button"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ni ni-bell-55"></i>
                    </a>
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Something else here</a>
                </base-dropdown>
                <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                  <a slot="title-container" class="nav-link" href="#" role="button">
                    <div class="media align-items-center">
                      <span class="avatar avatar-sm rounded-circle">
                        <img alt="Image placeholder" :src="user&&user.avatar_encode?`${apiUrl}/resources/image/${user.avatar_encode}`:'/img/noimage.png'">
                      </span>
                    </div>
                  </a>

                  <div class=" dropdown-header noti-title">
                      <h6 class="text-overflow m-0">Welcome!</h6>
                  </div>
                  <router-link to="/profile" class="dropdown-item">
                    <i class="ni ni-single-02"></i>
                    <span>My Profile</span>
                  </router-link>
                  <router-link to="/change-password" class="dropdown-item">
                    <i class="fa fa-unlock"></i>
                    <span>เปลี่ยนรหัสผ่าน</span>
                  </router-link>
                  <!-- <router-link to="/profile" class="dropdown-item">
                    <i class="ni ni-settings-gear-65"></i>
                    <span>Settings</span>
                  </router-link>
                  <router-link to="/profile" class="dropdown-item">
                      <i class="ni ni-calendar-grid-58"></i>
                      <span>Activity</span>
                  </router-link>
                  <router-link to="/profile" class="dropdown-item">
                      <i class="ni ni-support-16"></i>
                      <span>Support</span>
                  </router-link> -->
                  <div class="dropdown-divider"></div>
                  <a href="#!" class="dropdown-item" @click="logout()">
                    <i class="ni ni-user-run"></i>
                    <span>Logout</span>
                  </a>
                </base-dropdown>
              </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
                <hr class="my-3">

            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'
  import Config from '../../util/config';
import SessionStorageUtils from '../../util/sessionStorageUtils';

  export default {
    name: 'sidebar',
    data () {
      return {
        user: {},
        apiUrl: Config.apiUrl
      }
    },
    components: {
      NavbarToggleButton
    },
    props: {
      logo: {
        type: String,
        default: '/img/logo-white.png',
        description: 'Appbit Application Logo'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      },
      async logout(){
        this.AlertUtils.confirm("warning", "ยืนยันการออกจากระบบ", ()=>{
          SessionStorageUtils.deleteAllSession();
          localStorage.removeItem("delivery-countFilter");
          localStorage.removeItem("delivery-filterItem");
          localStorage.removeItem("filterGroups"); 
          this.$router.push("/login");
        });
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    },
    async mounted() {
      this.user = await SessionStorageUtils.decodeUser();
    }
  };
</script>
<style>
  #sidenav-main{
    background-image: url("/img/bg/03.jpg");
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12)!important;
  }
  .dropdown-item.active {
    color: #212529;
    background-color: transparent;
  }
</style>
