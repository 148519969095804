const Config = {
  apiUrl : process.env.VUE_APP_API_URL,
  //apiUrl: "http://212.80.213.109:3001", //staging
  //apiUrl: "http://192.168.0.76:3001", //staging
  //apiUrl: "https://erp.app-bit.com/api", //Production ห้ามใช้
  //apiUrl: "https://maewnam.thddns.net:7643/api", 
  //apiUrl: "http://localhost:3001",
  debug: process.env.VUE_DEBUG,
  apiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
  session: {
    user: "APPBIT_USER_SESSION"
  },
  pagination: {
    pages: [10, 25, 50],
    limit: 10
  },
  s3: {
    url: "https://s3storage.loveyouflower.com",
    email: "erp_user@appbit.com",
    password: "29b4IZUOm6ISCaYl"
  }
};

export default Config;
