import jwt_decode from "jwt-decode";
import Config from "./config";

const SessionStorageUtils = {
    async getSession(key) {
      let item = window.sessionStorage.getItem(key);
      return item;
    },
    async setSession(key, value) {
      window.sessionStorage.setItem(key, value);
    },
    async deleteSession(key) {
      window.sessionStorage.removeItem(key);
    },
    async deleteAllSession() {
      window.sessionStorage.clear();
    },
    async getUser() {
        let user = window.sessionStorage.getItem(Config.session.user);
        if(user){
            user = JSON.parse(user);
        }
        return user;
    },
    getUserNow() {
        let user = window.sessionStorage.getItem(Config.session.user);
        if(user){
            user = JSON.parse(user);
        }
        return user;
    },
    async setUser(obj) {
        window.sessionStorage.setItem(Config.session.user, JSON.stringify(obj));
    },
    async deleteUser() {
        window.sessionStorage.removeItem(Config.session.user);
    },
    async checkUser() {
        const user = await this.getUser();
        if(user){
          return true;
        }
        return false;
    },
    async decodeUser() {
        return new Promise(async(resolve, reject) => {
            const user = await this.getUser();
            if (user && user.token) {
                var decoded = jwt_decode(user.token);
                resolve(decoded);
            } else {
                resolve(null);
            }
        });
    }
}

export default SessionStorageUtils;
