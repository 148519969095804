import SessionStorageUtils from "../util/sessionStorageUtils";

const PermissionServices = {
  async getPermission(appSlug) {
    const user = await SessionStorageUtils.getUser();
    const permissions = user.permissions;
    let actionData = {};
    for(const v of permissions){
      if(v.app_slug==appSlug){
        if(v.action){
          actionData = JSON.parse(v.action);
        }
        break;
      }
    }

    return actionData;
  },
  async viewPermission(module, router) {
    const user = await SessionStorageUtils.getUser();
    const permissions = user.permissions;
    let flagView = false;
    for(const v of permissions){
      if(v.app_slug==module){
        if(v.action){
          const action = JSON.parse(v.action);
          flagView = action.flag_view;
        }
        break;
      }
    }

    if(!flagView){
      router.push(`/`);
    }
  }
}

export default PermissionServices;
