import moment from 'moment-timezone';
const timezone = "Asia/Bangkok";
const DateUtils = {
    date_format_tz(date, format) {
      if(!date){return '';}
      return moment(date).tz('Etc/UTC').format(format);
    },
    getCurrentDate() {
        const currentDate = moment().format("YYYY-MM-DD");
        return currentDate;
    },
    getCurrentDateTime() {
        const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
        return currentDate;
    },
    dateFormat(date, format) {
      if(!date){return '';}
      return moment(date).tz(timezone).format(format);
    },
    toDate(date) {
      if(!date){
        return null;
      }
        return new Date(date);
    },
    beforeDate(from, to){
      return moment(from).isBefore(moment(to));
    },
    afterDate(from, to){
      return moment(from).isAfter(moment(to));
    },
    toDateFormat(dateVal, format){
      if(!dateVal){return null;}
      dateVal = dateVal.replaceAll("Z", "");
      const tmps = dateVal.split("T");
      const date = new Date(dateVal);
      if(tmps.length>1){
        const mins = tmps[1].split(":");
        date.setHours(parseInt(mins[0]));
        date.setMinutes(parseInt(mins[1]));
        date.setSeconds(parseInt(mins[2]));
      }
      // return moment(dateVal, format);
      return date;
    }
};

export default DateUtils;
