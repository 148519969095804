const Constants = {
  noImageData: "/img/noimage.png",
  location_type: [
    { id: 1, text: "พื้นที่" },
    { id: 2, text: "วัด" },
    { id: 3, text: "แลนด์มาร์ค" },
  ],
  product_types: [
    { id: 1, text: "สินค้า" },
    { id: 2, text: "ค่าบริการ" },
    { id: 3, text: "อื่นๆ" },
  ],
  customer_types: [
    { id: 1, text: "นิติบุคคล" },
    { id: 2, text: "บุคคลธรรมดา" },
  ],
  customer_credit_types: [
    { id: 0, text: "ไม่มี" },
    { id: 1, text: "มี" },
  ],
  supplier_contract_status: [
    { id: 1, text: "มีผลอยู่" },
    { id: 2, text: "หมดอายุ" },
    { id: 3, text: "ยกเลิก" },
    { id: 4, text: "ระงับ" },
  ],
  status: [
    { id: 0, text: "Active" },
    { id: 1, text: "Inacive" },
  ],
  promotion_types: [
    { id: 1, text: "ตามระยะเวลาที่สั่ง (Order)" },
    { id: 2, text: "ตามระยะเวลาที่ส่ง (Delivery)" },
    { id: 3, text: "ตามระยะเวลาที่สั่งและส่ง (Both)" },
  ],
  promotion_status: [
    { id: 0, text: "Inactive" },
    { id: 1, text: "Active" },
    { id: 2, text: "Draft" },
  ],
  order_status: [
    { id: 0, text: "เปิดการขาย" },
    { id: 1, text: "ยืนยันการขาย" },
    { id: 2, text: "Approved" },
    { id: 3, text: "ส่งรายการให้ร้านค้า" },
    { id: 4, text: "ปิดการขายเรียบร้อย" },
    { id: 5, text: "พบปัญหารอการแก้ไข" },
    { id: 6, text: "คืนเงิน" },
    { id: 7, text: "ยกเลิก" },
  ],
  payment_status: [
    { id: 0, text: "รอชำระเงิน" },
    { id: 1, text: "รอตรวจสอบการชำระเงิน" },
    { id: 2, text: "ตรวจสอบการชำระเงินแล้ว" },
    { id: 3, text: "ยืนยันการชำระเงินแล้ว" },
    { id: 4, text: "ยกเลิกการชำระเงิน" },
  ],
  refund_status: [
    { id: 0, text: "ปกติไม่มีการคืนเงิน" },
    { id: 1, text: "คืนเงินบางส่วน" },
    { id: 2, text: "คืนเงินเต็มจำนวน" },
  ],
  delivery_status: [
    { id: 0, text: "ยังไม่ได้จัดส่ง" },
    { id: 1, text: "รอรูปจัดเสร็จ" },
    { id: 2, text: "ส่งรูปจัดเสร็จแล้ว" },
    { id: 3, text: "รอรูปผู้รับ" },
    { id: 4, text: "ส่งรูปผู้รับแล้ว" },
  ],
  inspections_status: [
    { id: 1, text: "รอตรวจสอบออเดอร์" },
    { id: 2, text: "ตรวจสอบออเดอร์แล้ว" },
  ],
  print_status: [
    { id: 0, text: "รอดำเนินการ" },
    { id: 1, text: "ดำเนินการ Print แล้ว" },
  ],
  issue_priority: [
    { id: 0, text: "ปกติ", value: 0 },
    { id: 1, text: "ด่วน", value: 1 },
    { id: 2, text: "ด่วนมาก", value: 2 },
  ],
  issue_status: [
    { id: 1, text: "เกิดปัญหาขึ้น แล้วแจ้งเป็น issue ขึ้นมา" },
    { id: 2, text: "เมื่อแก้ไขปัญหานั้นๆ แล้ว" },
    { id: 3, text: "ผ่านการตรวจสอบ" },
    { id: 4, text: "ไม่ผ่านการตรวจสอบ" },
  ],
  issue_approve_priority: [
    { id: 3, text: "ผ่านการตรวจสอบ", value: 3 },
    { id: 4, text: "ไม่ผ่านการตรวจสอบ", value: 4 },
  ],

  deliveries_status: [
    { id: 0, text: "Draft สร้างเพื่อเก็บข้อมูล" },
    { id: 1, text: "ส่งข้อมูลในซัพพลายเออร์แล้ว" },
    { id: 2, text: "รับรูปจัดเสร็จจากซัพพลายเออร์" },
    { id: 3, text: "ส่งรูปจัดเสร็จแล้วให้ลูกค้า" },
    { id: 4, text: "Onhold - พบปัญหา" },
    { id: 5, text: "รับรูปจากซัพพลายเออร์" },
    { id: 6, text: "ส่งรูป หน้างาน/ผู้รับ ให้ลูกค้า" }, // จาก "ส่งให้ลูกค้าแล้ว" เป็น "พบปัญหา-รอดำเนินการ"
    { id: 7, text: "ปิดการดำเนินการ" },
  ],
  payment_types: [
    { id: 0, text: "ยังไม่ระบุ" },
    { id: 1, text: "โอนเงิน - BBL" },
    { id: 2, text: "โอนเงิน - KBANK" },
    { id: 3, text: "โอนเงิน - KTB" },
    { id: 4, text: "บัตรเครดิต - 2C2P" },
    { id: 5, text: "บัตรเครดิต - Paypal" },
    { id: 6, text: "โอนเงิน - QR Code" },
    { id: 7, text: "ลูกค้าเครดิต" },
    { id: 8, text: "Qr code-WTB" },
    { id: 9, text: "k bank-WTB" },
    { id: 11, text: "Voucher" },
    { id: 12, text: "Voucher + เงินโอน" },
    { id: 13, text: "Voucher + บัตรเครดิต" },
  ],
  refund_bank: [
    { id: 0, text: "ยังไม่ระบุ" },
    { id: 1, text: "โอนเงิน - BBL" },
    { id: 2, text: "โอนเงิน - KBANK" },
    { id: 3, text: "โอนเงิน - KTB" },
    { id: 4, text: "บัตรเครดิต - 2C2P" },
    { id: 5, text: "บัตรเครดิต - Paypal" },
    { id: 6, text: "โอนเงิน - QR Code" },
    { id: 7, text: "ลูกค้าเครดิต" },
    { id: 8, text: "โอนเงิน KBANK-TBF" },
    { id: 9, text: "โอนเงิน QR Code-TBF" },
    { id: 10, text: "โอนเงิน - SCB" },
    { id: 11, text: "โอนเงิน - TTB" },
    { id: 12, text: "โอนเงิน - BAY" },
    { id: 13, text: "โอนเงิน - TMB" },
    { id: 14, text: "โอนเงิน - UOB" },
    { id: 15, text: "โอนเงิน - TBANK" },
    { id: 16, text: "โอนเงิน - GSB" },
  ],
  payment_methods: [
    { id: 1, text: "ชำระเงินเต็มจำนวน" },
    { id: 2, text: "แบ่งจ่าย" },
  ],
  upsale_status: [
    { id: 0, text: "รอการตรวจสอบ" },
    { id: 1, text: "อนุมัติ" },
    { id: 2, text: "ไม่อนุมัติ" },
  ],
  bill_types: [
    { id: 0, text: "ยังไม่ระบุ" },
    { id: 1, text: "บิลเงินสด" },
    { id: 2, text: "ใบกำกับภาษี" },
    { id: 3, text: "ไม่ออกบิล" },
  ],
  months: [
    "มค.",
    "กพ.",
    "มีค.",
    "เมย.",
    "พค.",
    "มิย.",
    "กค.",
    "สค.",
    "กย.",
    "ตค.",
    "พย.",
    "ธค.",
  ],
  issue_causes: [
    { id: 0, text: "ไม่ระบุ", value: "Not Selected" },
    { id: 1, text: "ซัพพลายเออร์", value: "Supplier" },
    { id: 2, text: "ลูกค้า", value: "Customer" },
    { id: 3, text: "ผู้ใช้งาน : CS", value: "User:CS" },
    { id: 4, text: "ผู้ใช้งาน : Admin", value: "User:Admin" },
    {
      id: 5,
      text: "ผิดร่วม : ซัพพลายเออร์,ผู้ใช้งาน",
      value: "Both:Supplier,User",
    },
    {
      id: 6,
      text: "ผิดร่วม : ซัพพลายเออร์,ลูกค้า",
      value: "Both:Supplier,Customer",
    },
    { id: 7, text: "ผิดร่วม : ลูกค้า,ผู้ใช้งาน", value: "Both:Customer,User" },
    {
      id: 8,
      text: "ผิดร่วม : ซัพพลายเออร์,ลูกค้า,ผู้ใช้งาน",
      value: "Both:Supplier,Customer,User",
    },
    { id: 9, text: "ปัญหาจากภายนอก", value: "Other" },
  ],
  refund: [
    { id: 1, text: "ออเดอร์ตกหล่น" },
    { id: 2, text: "สินค้าชำรุด เสียหาย / ไม่เหมือนแบบ" },
    { id: 3, text: "โอนเงินมาซ้ำ / โอนเกิน /สั่งซ้ำ" },
    { id: 4, text: "ระบบคิดค่าส่งจากทางเว็บ" },
    { id: 5, text: "จัดส่งไม่ได้ตามเวลาที่ลูกค้าต้องการ" },
    { id: 6, text: "เปลี่ยนสินค้า" },
    { id: 7, text: "เปลี่ยนวันส่ง" },
    { id: 8, text: "ไม่ได้สั่งซื้อโอนผิดบัญชี" },
    { id: 9, text: "งดรับพวงหรีด" },
  ],
};

export default Constants;
