import $ from 'jquery';
import DateUtils from '../util/dateUtils';

export default {
  bind: function(el, binding, vnode) {
    var value = binding.value;
    $(function(){
      var dateOption = {
          autoclose: true,
          format: 'dd/mm/yyyy',
      };
      $(el).datepicker(dateOption);

      const exp = binding.expression;
      const tmps = exp.split(".");
      const date = $(this).datepicker("getDate");
      const d = DateUtils.dateFormat(date, "DD/MM/YYYY");
      console.log(tmps);
      console.log(vnode.context);
      if(tmps.length>1){
        vnode.context[tmps[0]][tmps[1]] = date;
      }else if(tmps.length>0){
        vnode.context[tmps[0]] = date;
      }
      if(value){
        $(el).datepicker("update", new Date(value));
      }
      $(el).on("change", function(){
        const exp = binding.expression;
        const tmps = exp.split(".");
        const date = $(this).datepicker("getDate");
        const d = DateUtils.dateFormat(date, "DD/MM/YYYY");
        if(tmps.length>1){
          vnode.context[tmps[0]][tmps[1]] = date;
        }else if(tmps.length>0){
          vnode.context[tmps[0]] = date;
        }
      });
    });
  },
  // update: function (el, binding, vnode, oldVnode) {
  //   var ele = $(el).find("input");
  //   var dateOption = {
  //       autoclose: true,
  //       format: 'dd/mm/yyyy',
  //   };
  //   ele.datepicker(dateOption);
  //   if(binding.value){
  //     const d = DateUtils.dateFormat(binding.value, "DD/MM/YYYY");
  //     ele.datepicker('setDate', new Date(d));
  //   }
  // },
  // inserted: function (el, binding, vnode) {
  //     var ele = $(el).find("input");
  //     ele.on('change', function () {
  //       const exp = binding.expression;
  //       const tmps = exp.split(".");
  //       const date = $(this).datepicker("getDate");
  //       const d = DateUtils.dateFormat(date, "DD/MM/YYYY");
  //       if(tmps.length>1){
  //         vnode.context[tmps[0]][tmps[1]] = d;
  //       }else if(tmps.length>0){
  //         vnode.context[tmps[0]] = d;
  //       }
  //     });
  // },
};
