<template>
  <base-nav container-classes="container-fluid" class="navbar-top navbar-expand" :class="{'navbar-dark': type === 'default'}">
    <a href="#" aria-current="page" class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active">{{$route.name}}</a>
    <b-navbar-nav class="align-items-center ml-md-auto"></b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <b-link :to="'/order/add_new'" class="btn btn-primary order_blue">ทำรายการสั่งซื้อ</b-link>
      <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
        <a class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img  alt="Image placeholder" :src="user&&user.avatar_encode?`${user.avatar_encode}`:'/img/noimage.png'" />
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{user&&user.fullname?user.fullname:user.name}}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </b-dropdown-header>
          <b-dropdown-item>
            <i class="ni ni-single-02"></i>
            <span>
              <router-link to="/profile" class="text-dark">My Profile</router-link>
            </span>
          </b-dropdown-item>
          <b-dropdown-item>
            <i class="fa fa-unlock"></i>
            <span>
              <router-link to="/change-password" class="text-dark">เปลี่ยนรหัสผ่าน</router-link>
            </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="user.group_id==1">
            <i class="ni ni-single-02"></i>
            <span>
              <router-link to="/tools" class="text-dark">My Tools</router-link>
            </span>
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item href="#" @click="logout()">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import SessionStorageUtils from "../../util/sessionStorageUtils";
import Config from "../../util/config";

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)"
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      user: {},
      apiUrl: Config.apiUrl
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    async logout() {
      this.AlertUtils.confirm("warning", "ยืนยันการออกจากระบบ", () => {
        SessionStorageUtils.deleteAllSession();
        localStorage.removeItem("delivery-countFilter");
        localStorage.removeItem("delivery-filterItem");
        localStorage.removeItem("filterGroups");
        this.$router.push("/login");
      });
    }
  },
  async mounted() {
    this.user = await SessionStorageUtils.decodeUser();
    if (this.user.avatar) {
      try {
        this.user.avatar = JSON.parse(this.user.avatar) || "";
        this.user.avatar_encode = this.user.avatar.url || "";
      } catch (e) {}
    }
  }
};
</script>
